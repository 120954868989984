import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import DetailsModal from "@/components/DetailsModal";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  mixins: [withoutWatchersMixin],
  name: "Servis",
  components: {
    // HelloWorld,
    Multiselect,
    Pagination,
    DetailsModal,
    HasError,
  },
  data() {
    return {
      sl: null,
      usersEquipment: [],
      equipmentCategories: [],
      equipmentStatuses: [],
      subcategories: [],
      users: [],
      equipment: [],
      deleteId: null,
      item: null,
      services: {},
      finishItem: null,
      selectedCategory: null,
      selectedEquipmentType: null,
      selectedEquipmentStatus: null,
      selectedEquipment: null,
      selectedSubcategory: null,
      form: new Form({}),
      value: null,
      currentPage: 1,
      loading: false,
      deleting: false,
      modalTitle: "Dodaj servis",
      filters: {
        term: "",
      },
    };
  },
  methods: {
    edit(item) {
      this.modalTitle = "Izmijeni servis";
      this.item = item;
      this.form.id = item.id;
      this.form.equipment_id = item.equipment.id;
      this.form.description = item.description;
      this.selectedEquipment = item.equipment;
      this.$bvModal.show("modal-1");
    },
    deleteE(item) {
      this.deleteId = item.id;
      this.$bvModal.show("deleteEquipment");
    },
    deleteEquipment() {
      this.deleting = true;
      API_URL.delete(`equipment/${this.deleteId}`).then(() => {
        this.getAllEquipment();
        this.currentPage = 1;
        this.$toast.success(i18n.tc("notifications.success"));
        this.closeModal();
        this.deleting = false;
      });
    },
    finishServiceModal(i) {
      this.$bvModal.show("modal-1");
      this.finishItem = i;
      this.modalTitle = "Završi servis";
    },
    finishService() {
      this.loading = true;
      this.form.delete(`services/${this.finishItem.id}`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loading = false;
        this.currentPage = 1;
        this.form.reset();
        this.$bvModal.hide("modal-1");
        this.getServices();
        this.getAllEquipment();
      });
    },
    closeModal() {
      this.$bvModal.hide("details");
      this.modalTitle = "Dodaj servis";
      this.finishItem = null;
      this.form.reset();
      this.form.errors.errors = {};
      this.selectedEquipment = null;
      this.item = null;
    },
    submitForm() {
      if (this.item === null) {
        this.form
          .post("services")
          .then(() => {
            this.getServices();
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
            this.closeModal();
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form
          .put(`services/${this.item.id}`)
          .then(() => {
            this.getServices();
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
            this.closeModal();
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllEquipment() {
      API_URL.get("equipment-service").then((response) => {
        this.equipment = response.data.data;
      });
    },
    getEquipmentStatuses() {
      API_URL.get("equipment-statuses").then((response) => {
        this.equipmentStatuses = response.data;
      });
    },
    getSoloLeveling() {
      axios
        .get("http://localhost:1337/books/1", {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjM2NjQwMDM0LCJleHAiOjE2MzkyMzIwMzR9.6xbS6GZ6hn2aWFkZOfnVCyrS0B017AA_tGTghUZQOnE`,
          },
        })
        .then((res) => {
          this.sl = res.data;
        });
    },
    getServices() {
      this.loading = true;
      API_URL.get("services", {
        params: {
          history: false,
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.services = response.data;
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notifications",
      exporting: "exporting",
    }),
    resultsCount() {
      return this.services?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getServices();
      }, 250),
    },
    currentPage() {
      this.getServices();
    },
  },
  mounted() {
    this.getServices();
    this.getAllEquipment();
    this.getEquipmentStatuses();
    this.getSoloLeveling();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Aktivni servisi",
    });
  },
};
